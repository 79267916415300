<template>
  <layout name="Default">
    <authorize permission="emails.list" class="Emails">
      <router-view />
    </authorize>
  </layout>
</template>

<script>
export default {
  name: 'EmailsIndex'
}
</script>
